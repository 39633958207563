
/* *
{
    margin: 0; */
    /* padding: 0;
} */

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
  margin: 0;
  /* overflow-y: auto; /* Enable vertical scrolling */
} 

.loading-bar {
  position: absolute;
  /* justify-content: center; */
  top: 50%;
  width: 100%;
  height: 2px;
  background: white;
  transform: scaleX(0);
  transform-origin: top left;
  transition: transform 0.5s;
  opacity: 100%;
}


#loading-percent-number {
  display: flex;
  font-family: Arial, Helvetica, sans-serif;
  justify-content: center;
  /* left:10%; */
  font-size: 40pt;
  position: absolute;
  top: 50%;
  width: 100%;
  color: rgb(255, 240, 124);
  opacity: 100%;
}

.sectionsContainer {
  margin: 0;
}


html{
  font-size: 60%;
  
}

body {
  margin:0;
  background-color: rgb(189, 222, 252);
  font-family: 'Inter', sans-serif;
  padding: 0;
  
}

.wrapper {
  max-width: 100%;
  margin: 0;
  padding: 0;
}

header {
  display:flex; 
  justify-content: space-between;
  align-items: center;
}

.logoClass {
  margin-left: 2.0rem;
  width: 22rem;
  position: relative;
  z-index: 9999;
}

nav ul {
  list-style-type: none;
  display:flex;
  gap: 5.1em;
  margin:0;
}

nav ul li a {
  text-decoration: none;
  color: white;
  /* text-transform: uppercase; */
  font-size: 1.6rem;
  position: relative;
}


.nav-cta{
  background: #D9D9D9;
  border-radius: 1.3em;
  padding: 5px 10px;
}

.contactTopBar{
  color: #000000;
  background: #fac95e;
  border-radius: 0.7em;
  padding: 5px 12px;
}


.sectionsContainer {
  margin: 0;
}


.section_01 {


  max-width: 1200px;
  display: flex;
  align-items: center;
  height: 100vh;
  position: relative;
  color: #ffeded;
  font-size: 3vmin;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 20%;

  pointer-events: none;

  opacity:100%;
}


.section_02 {
  display: flex;
  align-items: center;
  height: 100vh;
  position: relative;
  color: #ffeded;
  font-size: 3vmin;
  padding-left: 10%;
  padding-right: 0%;
  padding-bottom: 10%;
  justify-content: flex-end;

  pointer-events: none;
}

.section_02_left-col{
  /* Need help with the 3D assets for your webGL project? */
  width: 380px;
  height: 268px;
  top: 237px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  /* line-height: 73px; */
  margin: 0;
  color: #FFFFFF;
  }


.section_03 {
  display: flex;
  align-items: center;
  height: 100vh;
  position: relative;
  color: #ffeded;
  font-size: 3vmin;
  padding-left: 10%;
  padding-right: 10%;

  pointer-events: none;
}


.section_04 {
  height: 100vh;
  display: flex;
  width:100%;
  position: relative;
  justify-content: center; /* Horizontally center */
  font-size: 3vmin;
  margin: 0;
  z-index: 9999;
  background-color: rgb(203, 194, 255);
  opacity: 0%;
}

.section_04_logo{
  margin-top: 60px;
  width: 220px;
}

.section_04_textBlock {
  font-size: 2.6rem;
  color: #313131;
}


.section_04_logoClass{
  margin-left: 3.0rem;
  width: 4rem;
  position: relative;
  /* background-color: #751b1b; */
  z-index: 9999;
}

.section_04_mainText{
  margin-top: 60px;
  font-size: 0.9em;
  margin-left: 3.0rem;
  margin-right: 3.0rem;
}

.fillCol{
  width: 50rem;
  /* background-color: aquamarine; */
}

.left-col{
/* Need help with the 3D assets for your webGL project? */
width: 420px;
height: 268px;
top: 237px;
font-family: 'Inter';
font-style: normal;
font-weight: 400;
/* line-height: 73px; */
margin: 0;
color: #FFFFFF;
opacity:0%;
}



.left-col h1 {
  font-size: 4.0rem;
  max-width: 50rem;
}

.left-col p {
  font-size: 2.6rem;
  max-width: 60rem;
  /* max-width: 40rem; */
}

/* Call to action button */


.cta-btns {
  margin-top: 10rem;
}

.cta-btns a{

/* Let’s chat about your project. */
  /* width: 115px;
  height: 48px; */
  text-decoration: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  background: #fac95e;
  border-radius: 1.2em;
  padding: 26px 30px;
}


.formParent{
  display: flex;
  align-items: center;
}

.container {
  margin-top: 60px;
  margin-bottom: 80px;
  width: 400px;
  /* margin: 0 auto; */
  padding: 20px;
  /* border: 1px solid #ccc; */
  border-radius: 2px;
  color: #a0a0a0;
  background-color: #313131;
}

h2 {
  text-align: center;
}

label {
  display: block;
  margin-top: 10px;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 5px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  
}

input[type="submit"] {

  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  border: none;
  background: #fac95e;
  border-radius: 0.9em;
  padding: 13px 30px;
  margin-top: 30px;
}

input[type="submit"]:hover {
  background-color: #d6b775;
}






















/* T A B L E T */

@media only screen and (min-width: 768px) and (max-width: 1023px) {

  /* body {background-color: blue;} */

body {
  margin:0;
  background-color: rgb(189, 222, 252);
  font-family: 'Inter', sans-serif;
  padding: 0;
  overflow-x: hidden;

}

.wrapper {

  max-width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

  

  .nav-cta{
    background: #751b1b;
    border-radius: 3.3em;
    padding: 5px 10px;
  }
  
  .textContainer {

    /* Rectangle 9 */
    position: relative;
    width: 40.0rem;
    height: 57.6rem;
    left: 1em;
    top: 100px;
    /* background: #E0A9A9; */
    }


          
  .section_01 {
    padding-left: 0%;
    height: 60vh;
    

  }

  .section_03 {
    padding-left: 0%;
  }

  .left-col h1 {
    font-size: 4.0rem;
    max-width: 40rem;

    padding-top: 50%;
  }
  
  .left-col p {
    font-size: 2.6rem;
    max-width: 40rem;
    /* max-width: 40rem; */
    padding-top: 0%;
  }

  .cta-btns {
    margin-top: 12rem;
  }

  .section_04 {
    display: flex;
    /* height: 60vh; */
    width:100%;
    position: relative;
    justify-content: center; /* Horizontally center */
    font-size: 3vmin;
    /* margin: 0; */
    z-index: 9999;
    background-color: rgb(203, 194, 255);
    overflow-x: hidden;
    
  }

    
}



















@media only screen and (max-width: 767px) and (orientation: portrait) {
  /* CSS rules for vertical (portrait) orientation on phones */

  .webgl {
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    margin: 0;
    /* overflow-y: auto; /* Enable vertical scrolling */
  } 


  body{
    margin:0;
    overflow-x: hidden;
    min-height: 800px;
  }

  nav ul li a {
    display: none;
  }

  
  .nav-cta{
    display: none;
  }
  
  .contactTopBar{
      display: none;
  }
  /* .wrapper {

    max-width: 100%;
    margin: 0;
    padding: 0;
  
  } */

  .section_01 {

    display: flex;
    align-items: center;
    margin-top: 38em;
    margin-bottom: 0em;
    height: 20vh;
    position: relative;
    color: #ffeded;
    font-size: 3vmin;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 0;



  }

  .section_02{
    display: none;
  }
  .section_03{
    display: none;
  }
  /* .section_04{
    display: none;
  } */

  .section_04 {

    display: flex;
      
    width:100%;
    position: relative;
    justify-content: center; /* Horizontally center */
    font-size: 3vmin;
    margin: 0;
    z-index: 9999;
    background-color: rgb(203, 194, 255);
    padding-bottom: 300px;
    
  }
  
  .section_04_logo{
    margin-top: 40px;
    width: 150px;
  }
  
  .section_04_textBlock {
    font-size: 2.3rem;
    color: #313131;
    max-width: 380px;

  }
  
  
  .section_04_logoClass{
  
    margin-left: 3.0rem;
    width: 4rem;
    position: relative;
    /* background-color: #751b1b; */
    z-index: 9999;
    
  }
  
  .section_04_mainText{
    
    font-size: 0.9em;
    /* margin-left: 3.0rem;
    margin-right: 3.0rem; */
    
  }

  .left-col{
    /* Need help with the 3D assets for your webGL project? */
    width: 100%;
    height: 268px;
    
    top: 237px;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    /* line-height: 73px; */
    margin: 0;
    
    color: #FFFFFF;
    }
    
    
    
    .left-col h1 {
      font-size: 2.5rem;
      
    }
    
    .left-col p {
      font-size: 2.1rem;
      max-width: 60rem;
      /* max-width: 40rem; */
    }

    .cta-btns a{
    display: none;
    }



    .formParent{
      display: flex;
      align-items: center;
      /* margin-left: -10%;
      margin-right: -10%; */
    }

    .container {
      margin-top: 60px;
      margin-bottom: 80px;
      width: 100%;
      /* margin: 0 auto; */
      padding: 20px;

      /* border: 1px solid #ccc; */
      border-radius: 2px;
      color: #a0a0a0;
      background-color: #313131;


    }
    
    h2 {
      text-align: center;
    }
    
    label {
      display: block;
      margin-top: 10px;
    }
    
    input[type="text"],
    input[type="email"],
    textarea {
      width: 100%;
      padding: 5px;
      margin-top: 5px;
      border: 1px solid #ccc;
      border-radius: 3px;
      
    }
    
    input[type="submit"] {
    
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
    
      color: #000000;
      border: none;
      background: #fac95e;
      border-radius: 0.9em;
      padding: 13px 30px;
      margin-top: 30px;
    
    
    
    }
    
    input[type="submit"]:hover {
      background-color: #d6b775;
      
    }
    










    
@media (orientation: landscape) {
  /* CSS rules for vertical (portrait) orientation on phones */

  .webgl {
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    margin: 0;
    /* overflow-y: auto; /* Enable vertical scrolling */
  } 


  body{
    margin:0;
    overflow-x: hidden;
  }

  nav ul li a {
    display: none;
  }

  
  .nav-cta{
    display: none;
  }
  
  .contactTopBar{
      display: none;
  }
  /* .wrapper {

    max-width: 100%;
    margin: 0;
    padding: 0;
  
  } */

  .section_01 {

    display: flex;
    align-items: center;
    margin-top: 43em;
    margin-bottom: 0em;
    height: 20vh;
    position: relative;
    color: #ffeded;
    font-size: 3vmin;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 0;



  }

  .section_02{
    display: none;
  }
  .section_03{
    display: none;
  }
  /* .section_04{
    display: none;
  } */

  .section_04 {

    display: flex;
    
    width:100%;
    
    position: relative;
    justify-content: center; /* Horizontally center */
  
    font-size: 3vmin;
    /* padding-left: 10%;
    padding-right: 10%; */
    padding-top: 0px;
    
     overflow-x: hidden;
    z-index: 9999;
    background-color: rgb(203, 194, 255);
    /* opacity: 99%; */
  }
  
  .section_04_logo{
    margin-top: 40px;
    width: 150px;
  }
  
  .section_04_textBlock {
    font-size: 2.3rem;
    color: #313131;
    max-width: 380px;

  }
  
  
  .section_04_logoClass{
  
    margin-left: 3.0rem;
    width: 4rem;
    position: relative;
    /* background-color: #751b1b; */
    z-index: 9999;
    
  }
  
  .section_04_mainText{
    
    font-size: 0.9em;
    /* margin-left: 3.0rem;
    margin-right: 3.0rem; */
    
  }

  .left-col{
    /* Need help with the 3D assets for your webGL project? */
    width: 100%;
    height: 268px;
    
    top: 237px;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    /* line-height: 73px; */
    margin: 0;
    
    color: #FFFFFF;
    }
    
    
    
    .left-col h1 {
      font-size: 7.5rem;
      
    }
    
    .left-col p {
      font-size: 4.1rem;
      max-width: 60rem;
      /* max-width: 40rem; */
    }

    .cta-btns a{
    display: none;
    }



    .formParent{
      display: flex;
      align-items: center;
      /* margin-left: -10%;
      margin-right: -10%; */
    }

    .container {
      margin-top: 60px;
      margin-bottom: 80px;
      width: 100%;
      /* margin: 0 auto; */
      padding: 20px;

      /* border: 1px solid #ccc; */
      border-radius: 2px;
      color: #a0a0a0;
      background-color: #313131;


    }
    
    h2 {
      text-align: center;
    }
    
    label {
      display: block;
      margin-top: 10px;
    }
    
    input[type="text"],
    input[type="email"],
    textarea {
      width: 100%;
      padding: 5px;
      margin-top: 5px;
      border: 1px solid #ccc;
      border-radius: 3px;
      
    }
    
    input[type="submit"] {
    
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
    
      color: #000000;
      border: none;
      background: #fac95e;
      border-radius: 0.9em;
      padding: 13px 30px;
      margin-top: 30px;
    
    
    
    }
    
    input[type="submit"]:hover {
      background-color: #d6b775;
      
    }
    


}



}
